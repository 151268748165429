




import { patientOptedOutReason } from '@/+state/patient-details/projections';
import { select } from '@conversa/reflex';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  setup() {
    const optedOutReason = select(patientOptedOutReason);

    return {
      optedOutReason
    };
  },
});
