import { OptOutReason } from './models';

export const optOutReasonMap = new Map<OptOutReason, OptOutReason>([
  ['opted_out_by_email_link', 'Opted Out by Email Link'],
  ['opted_out_by_email_bounce', 'Opted Out by Email Bounce'],
  ['opted_out_by_preferences', 'Opted Out through Patient Preferences'],
  ['opted_out_by_sms_stop', 'Opted Out by SMS Stop'],
  ['opted_out_by_termination', 'Opted Out by Termination'],
  ['opted_out_by_provider', 'Opted Out by Provider'],
  ['opted_out_by_terms_rejection', 'Opted Out by Terms Rejection']
]);
