















































import { computed, defineComponent } from '@vue/composition-api';
import StatusIndicatorIcon from '@/shared/components/StatusIndicatorIcon.vue';
import ActionMenu from './ActionMenu.vue';
import StatusPill from './StatusPill.vue';
import OptOutReasonLabel from './OptOutReasonLabel.vue';
import { select } from '@conversa/reflex';
import { patientDetailHeader } from '@/+state/patient-details/projections';
import { checkupHeader } from '@/+state/checkups/projections';
import { isMiscLoading } from '@/+state/misc/projections';

export default defineComponent({
  components: {
    ActionMenu,
    StatusPill,
    StatusIndicatorIcon,
    OptOutReasonLabel
  },
  setup(_, ctx) {
    return {
      isHome: computed(() => ctx.root.$route?.fullPath?.includes('/summary')),
      patientHeader: select(patientDetailHeader),
      checkupHeader: select(checkupHeader),
      loading: select(isMiscLoading),
    };
  },
});
